import { Fragment, useState } from "react"
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom"
import { ChatBubbleBottomCenterTextIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon as OutlineExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Trans, useTranslation } from "react-i18next"
import { Field, Form, Formik, useField, useFormikContext } from "formik";
import clsx from "clsx";
import _ from "lodash";

import { usePatents } from "../patents/PatentsProvider"
import { DocumentInfo, IpRight, PcCostCenter, PcCostCenterLink, PcIpRight, useDennemeyer, useDocumentInfo, useDocumentsPerId, useIpRight, useStopIpRight, useSynchronize, useTimeline, ValidationMessage } from "./DennemeyerProvider"
import { Member } from "../patents/patents"
import Modal from "../components/Modal"
import { IconSpinner } from "../components/icons";
import { deriveInitialIpRight, deriveStatus, extractDiscrepancy } from "./utils"
import { isOpen as isMaintenanceActionOpen } from "./settings/instruction_timeline";
import { isImported, isNotImportable, MaintenanceActionHistory, maintenanceActionHistoryToString, maintenanceActionsStatus, RenewalStatus, renewalStatusExtraction, RenewalStatusInfo, renewalStatusToString } from "./states";
import { parseNumber } from "../utils/strings";
import { DatePicker } from "../components/DatePicker";
import { missingInformation } from "./check";
import { linkDifference } from "../BackendProvider";
import { ipSubTypes as allIpSubTypes, origins } from '.'
import { CostCenterSelection } from "./IpImport";
import { Dialog, Popover, Transition } from "@headlessui/react";

export function MemberRenewalBibliographic({familyMemberId}: {familyMemberId?: number}) {

    const {memberById} = usePatents()
    const member = memberById[familyMemberId]
    const {ipRightByMemberId} = useDennemeyer()

    const pcIpRight = ipRightByMemberId[familyMemberId]

    const {ipRight: ipRightResp, isLoading} = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = ipRightResp?.Data

    if (member === undefined || familyMemberId === undefined) return null

    const params: DmParams =  {
        familyMemberId: familyMemberId,
        ipRightId: pcIpRight?.ipRightId,
        dennemeyerId: pcIpRight?.dennemeyerId,
    }

    return <RenewalFields {...{ member, pcIpRight, ipRight, params, isLoading }} />
}

function RenewalFields({ member, pcIpRight, ipRight, params, isLoading }: { member: Member, pcIpRight: PcIpRight, ipRight: IpRight, params: DmParams, isLoading?: boolean }) {
    const {t} = useTranslation()

    const { validationsByIpRightId, instructionByDennemeyerId } = useDennemeyer()
    const { calculateDueDates } = useTimeline()

    const renewalStatus = renewalStatusExtraction({ member, pcIpRight, ipRight, validationErrors: validationsByIpRightId[pcIpRight?.ipRightId] })
    const maintenanceActionHistory = maintenanceActionsStatus({renewalStatus: renewalStatus.status, maintenanceActions: ipRight?.MaintenanceActions, calculateDueDates, instructionByDennemeyerId})

    const fields = [
        { key: t('renewals-status'), value: <RenewalStateDisplay {...{ renewalStatus, params, pcIpRight, ipRight, isLoading, }} /> },
        ipRight !== undefined && { key: t('history'), value: <HistoryLink {...{ params, maintenanceActionHistory }} /> },
        ipRight !== undefined && { key: t('documents'), value: <DocumentsPerIpRight {...{ member, pcIpRight }} /> },
    ].filter(Boolean)

    return <>{
        fields.map(({ key, value }) =>
            <Fragment key={key}>
                <div className="sm:text-right text-gray-500">{key}</div>
                <div className="max-sm:pl-4">{value}</div>
            </Fragment>)
    }</>
}

function RenewalStateDisplay({pcIpRight, ipRight, params, renewalStatus, isLoading}: {pcIpRight: PcIpRight, ipRight: IpRight, params: DmParams, renewalStatus: RenewalStatusInfo, isLoading?: boolean}) {
    const {t} = useTranslation()

    const imported = isImported(renewalStatus.status, ipRight)
    const notImportable = !imported && isNotImportable(renewalStatus)

    const loading = isLoading && pcIpRight?.dennemeyerId !== undefined

    return (
        <div className="flex flex-col sm:flex-row gap-1 sm:gap-2 justify-between">
            <div onClick={() => console.log({ipRight})}>
                {renewalStatusToString(renewalStatus, t)} {loading && <IconSpinner className="h-3 w-3 mb-1 inline animate-spin text-pcx-600" />}
            </div>

            {!notImportable &&
                <Link to={{ pathname: "renewals", search: toQueryParams(params) }} className="btn-secondary text-sm py-px max-sm:self-end">
                    {t(renewalMainActionName({renewalStatus: renewalStatus.status, imported}))}
                    {/* {t(imported ? 'edit' : 'initiate')} */}
                </Link>}
        </div>
    )
}

function HistoryLink({maintenanceActionHistory, params}: {maintenanceActionHistory: MaintenanceActionHistory, params: DmParams}) {
    const {t} = useTranslation()

    if (maintenanceActionHistory.status === 'no-history') {
        return <span>{t('no-history')}</span>
    }

    return (
        <Link
            to={{ pathname: "renewals/history", search: toQueryParams(params) }}
            className="underline-link pl-0 sm:pl-0"
        >
            {maintenanceActionHistoryToString(maintenanceActionHistory, t)}
        </Link>
    )
}

export type DmParams = {
    familyMemberId: number,
    ipRightId?: number,
    dennemeyerId?: string
}

export function toQueryParams(params: DmParams) {
    return  createSearchParams(_.pickBy({
        familyMemberId: params?.familyMemberId?.toString(),
        ipRightId: params?.ipRightId?.toString(),
        dennemeyerId: params?.dennemeyerId?.toString(),
    }, v => v !== undefined)).toString()
}

// EDIT Modal
export function MemberRenwalsEdit() {
    const [searchParams] = useSearchParams()

    const {memberById } = usePatents()

    const { ipRightById, owners } = useDennemeyer()

    const ipRightId = parseNumber(searchParams.get('ipRightId'))

    const familyMemberId = parseNumber(searchParams.get('familyMemberId'))
    const member = memberById[familyMemberId]

    const dennemeyerId = searchParams.get('dennemeyerId') ?? undefined

    const pcIpRight = ipRightById[ipRightId]

    if (!member) {
        console.warn("Member not found")
        return null
    }

    const fallbackOwnerId = _(owners).minBy(o => o.name)?.ownerId

    if (fallbackOwnerId === undefined)
        return <NoOwnerModal />

    const missing = missingInformation(member)

    if (missing.length > 0) 
        return <NotEnoughDataModal {...{missingInformation: missing, familyMemberId}} />

    // if (isIgnored(familyMemberId))
    //     return <ReconsiderModal {...{member}} />

    return <MemberPostRenewal {...{member, pcIpRight, dennemeyerId, fallbackOwnerId}} />
}

// function ReconsiderModal({member}: {member: Member}) {
//     const {t} = useTranslation()

//     const {reconsider} = useDennemeyer()

//     return (
//         <Modal>
//             <div className="p-4 bg-white">
//                 <h2>Reconsider {member.internalReference} for Payment Handling?</h2>
//             </div>
//             <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-4">
//                 <button onClick={() => reconsider(member.familyMemberId)} className="btn-primary">Reconsider</button>
//                 <Link className="btn-secondary" to=".">{t('back')}</Link>
//             </div>
//         </Modal>
//     )
// }

type FormPcIpRight = PcIpRight & {linked?: number, costCenters: PcCostCenterLink[], ownerId: number}

function MemberPostRenewal({member, dennemeyerId, pcIpRight, fallbackOwnerId}: {member: Member, dennemeyerId?: string, pcIpRight?: PcIpRight, fallbackOwnerId: number}) {
    const {t} = useTranslation()

    const navigate = useNavigate()

    const {ipRight: ipRightResp} = useIpRight(dennemeyerId)
    const ipRight = ipRightResp?.Data

    const {parents, deleteOriginationLink, postOriginationLink} = usePatents()
    
    const familyMemberId = member.familyMemberId
    const linked = parents[familyMemberId]?.[0]?.familyMemberId

    const {
        postIpRight, 
        costCentersByIpRightId,  postCostCenterLink, deleteCostCenterLink,
        validationsByIpRightId, 
        ownershipsByIpRight, owners, postOwnershipLink, deleteOwnershipLink,
        //isIgnored, //ignore,
    } = useDennemeyer()

    const ipRightId = pcIpRight?.ipRightId

    const {triggerSynchronize, synchronizing} = useSynchronize()

    // async function doIgnore() {
    //     await ignore(familyMemberId)
    //     navigate("..")
    // }

    // TODO: remove
    const discrepancy = ipRight && extractDiscrepancy(member, ipRight)

    if (discrepancy) {
        console.warn(`Patent Cockpit: ${discrepancy.pc} vs. Dennemeyer: ${discrepancy.dm}`)
    }

    const validations = validationsByIpRightId[ipRightId] ?? []
    const {status: renewalStatus} = renewalStatusExtraction({ member, pcIpRight, ipRight, validationErrors: validations })
    const imported = isImported(renewalStatus, ipRight)

    if (renewalStatus === 'missing-data') {
        console.warn("Missing data. This shouldn't happen here...")
    }

    //const canInitiate = renewalStatus === 'can-import'
    const canStop = ipRight?.Status !== 'Inactive' && imported
    //const isReactivating = renewalStatus === 'stopped' && imported

    //console.log({ipRight, pcIpRight})

    const initialValues: FormPcIpRight = pcIpRight ?
        {
            ...pcIpRight,
            linked,
            costCenters: costCentersByIpRightId[pcIpRight.ipRightId] ?? [],
            ownerId: ownershipsByIpRight[pcIpRight.ipRightId]?.[0]?.ownerId ?? fallbackOwnerId,
        } : {
            ...deriveInitialIpRight(member),
            costCenters: [],
            ownerId: fallbackOwnerId,
        }
    return (
        <Modal {...{overflowHidden: false}}>
            <Formik
                initialValues={initialValues}
                onSubmit={async (ipRight: FormPcIpRight) => {
                    const { status } = deriveStatus(member)
                    const { ipRightId } = (await postIpRight({...ipRight, status})) as PcIpRight
                    const existing = ownershipsByIpRight[ipRightId]?.[0]
                    if (existing) {
                        await deleteOwnershipLink(existing)
                    }
                    await postOwnershipLink({ ipRightId, ownerId: ipRight.ownerId, ownerType: 'registered' })

                    const [toAdd, toDelete] = linkDifference(
                        costCentersByIpRightId[ipRightId] ?? [],
                        ipRight.costCenters.map(cc => ({...cc, ipRightId})),
                        (a: PcCostCenterLink, b: PcCostCenterLink) => a.ipRightId === b.ipRightId && a.costCenterId === b.costCenterId && a.percentage === b.percentage)

                    if (toDelete.length > 0) 
                        await deleteCostCenterLink(toDelete)
                    if (toAdd.length > 0)
                        await postCostCenterLink(toAdd)

                    if (linked !== ipRight.linked) {
                        if (linked)
                            await deleteOriginationLink({from: familyMemberId, to: linked})
                        if (ipRight.linked)
                            await postOriginationLink({from: familyMemberId, to: ipRight.linked})
                    }

                    await triggerSynchronize([ipRightId])
                    navigate("..")
                }}
                enableReinitialize
            >{({isSubmitting}) => 
                <Form>
                    <div className="p-4 space-y-4">
                        <h2>{member.internalReference}: {t('payment-handling')}</h2>
                        {/* {discrepancy && 
                            <div>
                                <ExclamationTriangleIcon className="w-5 h-5 inline-block text-warn-500 mr-1" />
                                Patent Cockpit: {discrepancy.pc} vs. Dennemeyer: {discrepancy.dm}
                            </div>} */}
                        <ValidationErrors {...{validations}} />
                        <div className="grid grid-cols-1 sm:grid-cols-[auto_auto] gap-x-3 gap-y-2 items-center">

                            <SubTypeSelector {...member} />

                            <label htmlFor="origin" className="label" title="Origin">{t('forecast.filing-procedure')}</label>
                            <Field id="origin" name="origin" as="select" className="form-select">
                                {origins.map(origin => <option key={origin.name} value={origin.name}>{t(origin.description)}</option>)}
                            </Field>

                            <label htmlFor="ownerId" className="label">{t('owner')}</label>
                            <Field id="ownerId" name="ownerId" as="select" className="form-select">
                                {owners.map(owner => <option key={owner.ownerId} value={owner.ownerId}>{owner.name}</option>)}
                            </Field>

                            <label htmlFor="costCenters" className="label">{t('cost-centers')}</label>
                            <CostCenterSelectionField name="costCenters" ipRightId={ipRightId} />

                            <div className='sm:col-span-2 border-b-2 border-slate-600/20' />

                            <label htmlFor="firstDueDate" className="label self-start sm:pt-2.5 normal-case">{t('first-due-date-with-payment-provider')}</label>
                            <div>
                                <Field name="firstDueDate" className="form-input" as={DatePicker} />
                                <p className='max-w-prose text-xs text-slate-600 mt-2'>{t('first-due-date-comment')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 bg-pcx-200 p-4 rounded-b-xl overflow-hidden">
                        <div className="flex flex-col sm:flex-row-reverse gap-4 sm:items-baseline">
                            <button type="submit" className={clsx("btn-primary", "inline-block whitespace-nowrap shrink-0 w-fit")} disabled={synchronizing}>
                                {t(renewalMainActionName({renewalStatus, imported}))}
                                {(synchronizing || isSubmitting) && <IconSpinner className="inline ml-1 h-5 w-5 animate-spin" />}
                            </button>
                            <Link className="btn-secondary text-center" to="..">{t('back')}</Link>
                            <div className="sm:mr-auto">
                                {canStop
                                    ? <StopDmRightButton {...{ member, ipRight, ipRightId, disabled: !canStop }} />
                                    : null /*<button type="button" onClick={() => doIgnore()} className="btn-warn-tertiary whitespace-nowrap">
                                        {t('ignore')}
                                    </button>*/}
                            </div>
                        </div>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}

function ValidationErrors({validations}: {validations: ValidationMessage[]}) {
    const {t} = useTranslation()

    return validations.length > 0
        ? <div className="border-b-2 border-slate-600/20 pb-2">
            <p className="text-red-900 font-medium mb-1">{t("errors-from-last-attempt")}</p>
            <ul className="list-disc space-y-2 pl-8">
                {validations.map(v =>
                    <li key={v.validationId} className="text-red-700 list-outside">
                        <span title={v.message}>{t("dm_messages." + v.errorCode)}</span>
                        <Popover className="relative inline-block ml-2">
                            <Popover.Button><ChatBubbleBottomCenterTextIcon className="inline-block h-4 w-4 " /></Popover.Button>
                            <Popover.Panel className="absolute z-10 max-w-prose p-4 bg-white rounded-md shadow-lg text-xs border ring-1 ring-red-700/5">
                                <p className="text-slate-700">{v.message}</p>
                                <div className="flex flex-row-reverse gap-4 mt-4">
                                    <Popover.Button className="btn-secondary">{t('close')}</Popover.Button>
                                    <Popover.Button as="a" href={`mailto:support@patent-cockpit.com?body=${encodeURIComponent(v.message)}`} className="btn-tertiary">{t("support")}</Popover.Button>
                                </div>
                            </Popover.Panel>
                        </Popover>
                    </li>
                )}
            </ul>
        </div>
        : null
}

function renewalMainActionName({renewalStatus, imported}: {renewalStatus: RenewalStatus, imported: boolean}) {
    const canInitiate = renewalStatus === 'can-import'
    const isReactivating = renewalStatus === 'stopped' && imported

    return isReactivating ? 'reactivate-payment-handling' 
           : canInitiate ? 'initiate-payment-handling' 
           : 'edit-payment-handling'
}

function SubTypeSelector({familyMemberId, patentFamilyId, ipType}: Member) {
    const {t} = useTranslation()
    // At first we assume that there will be only one parent (for divisionals and continuations)
    const {membersByFamilyId} = usePatents()

    const {values, setFieldValue} = useFormikContext<FormPcIpRight>()

    const ipSubTypes = allIpSubTypes[ipType] ?? []

    const ipSubType = values.ipSubType
    const members = (membersByFamilyId[patentFamilyId] ?? []).filter((m: Member) => m.familyMemberId !== familyMemberId)
    const linkedEnable = ipSubTypes.find(t => t.name === ipSubType)?.withLink && members.length > 0 

    const name = 'ipSubType'
    return <>
        <label htmlFor={name} className="label" title="ipSubType">{t('specialIpType')}</label>
        <Field id={name} name={name} as="select" className="form-select">
            {ipSubTypes.map(type => <option key={type.name} value={type.name}>{t(type.label ?? type.name)}</option>)}
        </Field>
        <label htmlFor='linked' className={clsx('label', !linkedEnable && 'opacity-40')} title="Former">{t('linked-ip-right')}</label>
        <select
            name="linked" id="linked" className='form-select disabled:opacity-40' disabled={!linkedEnable}
            value={(linkedEnable && values.linked) ?? '-1'} onChange={e => setFieldValue('linked', e.target.value)}
        >
            <option value={-1}>{t('select')}...</option>
            {_(members)
                .sortBy(m => m.internalReference.toLowerCase())
                .map((m: Member) =>
                    <option key={m.familyMemberId} value={m.familyMemberId}>
                        {m.internalReference}
                    </option>)
                .value()}
        </select>
    </>
}

function CostCenterSelectionField({name, ipRightId}: {name: string, ipRightId?: number}) {
    const [, meta, helpers] = useField<(PcCostCenter & PcCostCenterLink)[]>(name)
    const { setValue } = helpers
    const { value } = meta

    return <CostCenterSelection {...{
        value, ipRightId, onChange: ({target: {value}}) => {
            setValue(value)
        }
    }} />
}

function NoOwnerModal() {
    const {t} = useTranslation()
    return (
        <Modal>
            <div className="p-4">
                <h3 className="mb-2">{t('no-owner-defined')}</h3>
                <p>
                    <Trans
                        i18nKey='define-owner'
                        components={{ here: <Link className="underline decoration-pcx-500 hover:decoration-pcx-400" to="/renewals/owners/add" /> }}
                    />
                </p>
            </div>
            <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                <Link className="btn-primary" to="/renewals/owners/add">{t('add-owner-details')}</Link>
                <Link className="btn-secondary" to="..">{t('cancel')}</Link>
            </div>
        </Modal>
    )
}

function NotEnoughDataModal({missingInformation, /*familyMemberId*/}: {missingInformation: string[], familyMemberId: number}) {
    const {t} = useTranslation()

    // const {ignore} = useDennemeyer()
    // const navigate = useNavigate()

    const translated = _(missingInformation).map(missing => t(missing)).sortBy().value()

    // async function doIgnore() {
    //     await ignore(familyMemberId)
    //     navigate("..")
    // }

    return (
        <Modal>
            <div className='p-4'>
                <h2 className='mb-4'>{t("missing-information")}</h2>
                <p className='sm:text-lg text-gray-800'>{t("missing-information-no-registration")}</p>
                <div className='flex flex-row gap-4 items-center'>
                    <div className='max-sm:hidden px-4 text-warn-600'><OutlineExclamationTriangleIcon className='h-12 w-12' /></div>
                    <ul className='leading-relaxed text-lg lg:columns-xs lg:max-h-32'>
                        {translated.map(missing => <li key={missing}>{missing}</li>)}
                    </ul>
                </div>
                <p className='mt-4 sm:text-lg text-gray-800'>{t("edit-case-complete-info")}</p>
            </div>
            <div className='p-4 bg-pcx-200 flex flex-row-reverse gap-4'>
                <Link className='btn-primary' to={"../edit"}>{t('edit')}</Link>
                <Link className='btn-secondary' to={".."}>{t('back')}</Link>

                {/* <button onClick={() => doIgnore()} className='btn-tertiary sm:mr-auto'>{t('ignore')}</button> */}
            </div>
        </Modal>
    )
}

function StopDmRightButton({member, ipRight, ipRightId, disabled}: {member: Member, ipRight: IpRight, ipRightId?: number, disabled?: boolean}) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {postMember} = usePatents()
    const {calculateDueDates} = useTimeline()
    const { fullStop } = useStopIpRight(ipRightId)
    
    const [isOpen , setIsOpen ] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const alreayStoppedWithPc = member?.familyMemberStatus === 'stopped'
    const [alsoStopWithPc, setAlsoStopWithPc] = useState(true)

    const closeModal = () => setIsOpen(false)

    async function handleStop() {
        if (!isSubmitting) {
            setIsSubmitting(true)
            if (alsoStopWithPc && !alreayStoppedWithPc) {
                await postMember({ ...member, familyMemberStatus: 'stopped' })
            }
            await fullStop()
            setIsSubmitting(false)
            setIsOpen(false)
            navigate("..")
        }
    }

    const openMaintenanceActions = (ipRight?.MaintenanceActions ?? [])
        .map(ma => ({...ma, ...calculateDueDates(ma.DueDate)}))
        .filter(ma => isMaintenanceActionOpen(ma.status))

    return (
        <>
            <button {...{ disabled }} type="button" onClick={() => setIsOpen(!isOpen)} className='shrink-0 btn-warn-tertiary whitespace-nowrap'>
                {t('stop-payment-handling')}
            </button>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    {/* Background */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-lg transition-all">
                                    <Dialog.Title as="h3" className="p-4" >
                                        {member.internalReference}: {t('renewals-desc.stop')}
                                    </Dialog.Title>
                                    <div className="p-4 pt-0">
                                        <p className='font-base text-gray-500'>
                                            {t('renewals-desc.really-stop')}
                                        </p>
                                    </div>
                                    {openMaintenanceActions.length > 0 &&
                                        <div className='px-4 pb-4'>
                                            <div className='bg-warn-200 rounded px-4 py-2 text-warn-900 flex flex-row gap-4 items-center'>
                                                <ExclamationTriangleIcon className="w-8 h-8 inline-block text-warn-500" />
                                                {/* TODO: Translations */}
                                                <div>
                                                    <p>The following fee payments will be cancelled as well</p>
                                                    <ul>
                                                        {openMaintenanceActions.map(ma =>
                                                            <li key={ma.DennemeyerId}>
                                                                {ma.Annuity && `Annuity ${ma.Annuity}; `}Due: {ma.instructionDueDate}
                                                            </li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>}
                                    {!alreayStoppedWithPc && <label className='flex flex-row gap-2 px-4'>
                                        <input type="checkbox" checked={alsoStopWithPc} onChange={() => setAlsoStopWithPc(!alsoStopWithPc)} />
                                        <span className='text-gray-500'>{t('stop-pc-case')}?</span>
                                    </label>}

                                    <div className="mt-4 p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                                        <button type="button" className='btn-warn' onClick={() => handleStop()}>
                                            {t('stop')} {isSubmitting && <IconSpinner className="inline w-5 h-5 animate-spin" />}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-secondary"
                                            onClick={closeModal}
                                        >
                                            {t('back')}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

// DOCUMENTS
function DocumentsPerIpRight({member, pcIpRight}: {member: Member, pcIpRight?: PcIpRight}) {
    const {t} = useTranslation()
    const {documents} = useDocumentsPerId(pcIpRight?.dennemeyerId)

    const [isOpen, setIsOpen] = useState(false)

    const docs = _(documents?.Data?.Page ?? []).sortBy('DocumentDate').reverse().value()

    const count = docs.length
    if (count === 0) return <div>{t('documents-count', {count: 0})}</div>

    return <div>
        <button className='underline-link' type="button" onClick={() => setIsOpen(true)}>
            {t('documents-count', {count})}
        </button>

        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-sm bg-pcx-100 border-2 border-pcx-500 text-left align-middle shadow-lg transition-all">
                                <div className="p-4">
                                    <button className='text-slate-500 capitalize text-sm mb-3' onClick={() => setIsOpen(false)}>
                                        <ChevronLeftIcon className="h-4 w-4 inline-block mb-0.5" /> {t('back')}
                                    </button>
                                    <Dialog.Title as="h3" className="mb-2 pl-px" >
                                        {member.internalReference}: {t('documents')}
                                    </Dialog.Title>
                                    {docs.map(document => <DocumentCard key={document.DennemeyerId} document={document} />)}
                                </div>

                                <div className="flex flex-row-reverse p-4 pt-0 bg-pcx-100">
                                    <button
                                        type="button"
                                        className="btn-primary"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {t('close')}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </div>
}

function DocumentCard({document}: {document: DocumentInfo}) {
    const {t} = useTranslation()
    const {downloadDocument} = useDocumentInfo(document.DennemeyerId)
    return (
    <div className='p-4 bg-white rounded-lg shadow mb-4 last:mb-0 grid grid-cols-2 gap-2 items-baseline'>
        <h4>{t(document.DocumentType)}</h4>
        <span className='text-slate-600 text-right'>{document.DocumentDate}</span>
        
        <button 
            className='text-left col-span-2 text-lg underline text-pcx-600 hover:text-pcx-500'
            title={t('download')} onClick={() => downloadDocument(document.DocumentName)}
        >
            {document.DocumentName}
        </button>
    </div>
    )
}