import { Link, useLocation, useNavigate } from "react-router-dom"
import { useRoles } from "../../user/Auth"
import { useTranslation } from "react-i18next"
import { useDennemeyer, useIpRight, useSynchronize } from "../../renewal/DennemeyerProvider"
import { useDocumentSettings } from "../../documents/DocumentsSettings"
import { useAgorumObject, useFindUuid } from "../../documents/backend"
import { useState } from "react"
import Modal from "../Modal"
import AutomaticBackgroundChanges from "./AutomaticBackgroundChanges"
import _ from "lodash"
import { useMember } from "../../patents/FamilyMember"
import { family_member } from "../../data"
import { AfterUpdateAction, afterUpdateActionsStr, AutomaticUpdateAction, ManualUpdateAction } from "."
import { Member } from "../../patents/patents"
import { DmParams, toQueryParams } from "../../renewal/MemberOverview"
import { isImported, renewalStatusExtraction } from "../../renewal/modern_states"

export function MemberChanged() {
  const {hasAnnuities, hasDocuments} = useRoles()

  const location = useLocation()
  const {member} = useMember() 

  if (!hasAnnuities && !hasDocuments)
    return null

  const state = location.state ?? {}

  const changes = state[afterUpdateActionsStr] ?? []
  if (changes.length === 0 || member.familyMemberId === undefined)
    return null

  const {from, to} = state

  return <MemberChangedView {...{changes, from, to, hasAnnuities, hasDocuments}} />
}

function splitAutomaticChanges(changes: AfterUpdateAction[])  {
    return _.partition(changes, c => ['move-folder', 'update-reference'].includes(c)) as [AutomaticUpdateAction[], ManualUpdateAction[]]
}

function MemberChangedView(
    {changes, from, to, hasAnnuities, hasDocuments}: 
    {changes: AfterUpdateAction[], from?: Member, to: Member, hasAnnuities: boolean, hasDocuments: boolean}
) {
    const [isAutomaticDone, setIsAutomaticDone] = useState(false)
    const { member, family } = useMember()

    const { ipRightByMemberId, postIpRight } = useDennemeyer()
    const pcIpRight = ipRightByMemberId?.[member.familyMemberId]
    const { ipRight: ipRightResp, isLoading: isLoadingIpRight } = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = ipRightResp?.Data

    const renewalStatus = renewalStatusExtraction({ member: to, pcIpRight, ipRight })
    const imported = isImported(renewalStatus)

    const { triggerSynchronize } = useSynchronize()

    function updateReference() {
        // console.log("Updating ref with DM")
        return triggerSynchronize([pcIpRight.ipRightId])
    }

    async function stopCaseWithDm() {
        if (pcIpRight) {
            await postIpRight({ ...pcIpRight, status: 'Inactive' })
            await triggerSynchronize([pcIpRight?.ipRightId])
        }
    }

    const { number } = useDocumentSettings()
    const { uuid } = useFindUuid(number, family_member, from?.internalReference, family.internalReference)
    const { object, update, isLoading } = useAgorumObject({ uuid })

    async function moveFolder() {
        if (uuid === object.uuid) {
            // console.log("Moving folder")
            return update({ ...object, name: to.internalReference })
        }
    }

    const hasFolder = uuid !== undefined
    const dmRefNotUpdated = ipRight?.CustomerReferenceNumbers?.[0] !== to.internalReference

    const actions = changes.filter(c =>
        (c === 'move-folder' && hasDocuments && hasFolder) ||
        (c === 'initiate-payment-handling' && hasAnnuities) ||
        (c === 'stop-payment-handling' && hasAnnuities && imported) ||
        (c === 'update-reference' && hasAnnuities && imported && dmRefNotUpdated)
    )

    // manual can only be empty, 'initiate-payment-handling' or 'stop-payment-handling'
    const [automatic, manual] = splitAutomaticChanges(actions)

    const automaticActions = automatic.map(a => {
        switch (a) {
            case 'move-folder':
                return { key: a, label: 'Renaming the folder', action: moveFolder }
            case 'update-reference':
                return { key: a, label: 'Updating the reference with the payment handler', action: updateReference }
        }
    }).filter(Boolean)

    const manualAction = manual[0]
    const params: DmParams = {
        familyMemberId: member.familyMemberId,
        ipRightId: pcIpRight?.ipRightId,
        dennemeyerId: pcIpRight?.dennemeyerId,
    }

    return <>
        {manualAction && <ChangePaymentHandling {...{manualAction, params, stopCaseWithDm}} />}
        {!isAutomaticDone && !isLoading && !isLoadingIpRight &&
            <AutomaticBackgroundChanges {...{
                actions: automaticActions,
                close: () => setIsAutomaticDone(true)
            }} />}
    </>
}

function ChangePaymentHandling(
    {manualAction, params, stopCaseWithDm}:
    {manualAction: 'initiate-payment-handling' | 'stop-payment-handling', params: DmParams, stopCaseWithDm: () => Promise<void>}) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const doInitiate = manualAction === 'initiate-payment-handling'

    const initiateLink = `renewals?${toQueryParams(params)}`

    async function stop() {
        await stopCaseWithDm()
        navigate('.')
    }

    return (
        <Modal escAction={() => navigate('.')}>
            <div className="p-4 space-y-2">
                <h2>You have changed the status of your patent</h2>
                <p className="text-slate-500">Do you wish to propagate these changes to the payment provider?</p>
            </div>
            <div className="bg-pcx-200 flex flex-row-reverse gap-4 p-4">
                {doInitiate
                    ? <Link
                        className="btn-primary"
                        to={initiateLink}
                    >{t('initiate-payment-handling')}</Link>
                    : <button className="btn-primary" onClick={stop}>{t('stop-payment-handling')}</button>}
                <Link to="." className="btn-secondary">{t('dismiss')}</Link>
            </div>
        </Modal>
    )
}