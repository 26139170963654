import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { useFilteredPatents } from "../../filter/FilteredPatents";
import { IpRight, MaintenanceAction, PcCostCenter, PcCostCenterLink, useDennemeyer, useFullIpRightsPortfolio, useMaintenances, useTimeline, ValidationMessage } from "../DennemeyerProvider";
import { maintenanceActionHistoryToString, maintenanceActionsStatus, MaintenanceActionStatusInfo, renewalStatusExtraction, RenewalStatusInfo, renewalStatusToString, RenewalSubStatus } from "../modern_states";
import _ from "lodash";
import { Member } from "../../patents/patents";
import { createSearchParams, Link, useSearchParams } from "react-router-dom";
import { memberUrl } from "../../patents/utils";
import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { SortButton } from "../../patents/datawizard/DataWizard";
import { CheckCircleIcon, ExclamationCircleIcon, PlayCircleIcon, StopCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import Modal from "../../components/Modal";
import { Field, Form, Formik, useField } from "formik";
import { CostCenterSelection } from "../IpImport";
import { useIpRightsImport } from "./PaymentHandlingProvider";

function canBeSelected(renewalStatus: RenewalStatusInfo) {
    return renewalStatus.substatus === 'can-import' || renewalStatus.substatus === 'has-errors'
}

const AnnuityContext = createContext({
    isLoadingAnnuity: false as boolean,
    maintenanceActionsByDennemeyerId: {} as Record<string, MaintenanceAction[]>,
    ipRightByDennemeyerId: {} as Record<string, IpRight>,
})

function AnnuityProvider({children}) {

    const { data: ipRights, fetchNextPage: fetchNextIpRightsPage, hasNextPage: hasNextIpRightsPage, isFetching: isFetchingPortfolio } = useFullIpRightsPortfolio()
    if (hasNextIpRightsPage) {
        //console.log('fetching next page')
        fetchNextIpRightsPage()
    }

    const ipRightByDennemeyerId = _(ipRights?.pages?.flatMap(p => p.Data?.Page ?? []) ?? [])
        .filter(ipRight => !isNaN(+ipRight.UniqueCaseKey))
        .keyBy(ipRight => ipRight.DennemeyerId)
        .value()

    const {data: maintenanceActions, fetchNextPage, hasNextPage, isFetching: isFetchingMaintenances} = useMaintenances({/*minDate, maxDate,*/ onlyInstructable: false, onlyOpen: false})
    if (hasNextPage) {
        fetchNextPage()
    }

    const maintenanceActionsByDennemeyerId = _(maintenanceActions?.pages?.flatMap(p => p.Data?.Page ?? []) ?? [])
        .filter(ma => ma?.IpRightInfo?.DennemeyerId in ipRightByDennemeyerId)
        .groupBy(ma => ma?.IpRightInfo?.DennemeyerId)
        .value()

    const isLoadingAnnuity = isFetchingMaintenances || isFetchingPortfolio

    const value = isLoadingAnnuity 
        ? {isLoadingAnnuity, maintenanceActionsByDennemeyerId: {}, ipRightByDennemeyerId: {}} 
        : {isLoadingAnnuity, maintenanceActionsByDennemeyerId, ipRightByDennemeyerId}

    return <AnnuityContext.Provider {...{value}}>
        {children}
    </AnnuityContext.Provider>
}

function useAnnuity() {
    return useContext(AnnuityContext)
}

const BulkImportContext = createContext({
    sortField: 'internalReference' as string,
    setSortField: (() => {}) as Dispatch<SetStateAction<string>>,
    sortOrder: 1 as number,
    setSortOrder: (() => {}) as Dispatch<SetStateAction<number>>,
    selected: {} as Record<number, boolean>,
    setSelected: (() => {}) as Dispatch<SetStateAction<Record<number, boolean>>>,
})

function BulkImportProvider({children}) {
    const [sortField, setSortField] = useState('internalReference')
    const [sortOrder, setSortOrder] = useState(1)
    const [selected, setSelected] = useState({})

    return <BulkImportContext.Provider value={{sortField, setSortField, sortOrder, setSortOrder, selected, setSelected}}>
        {children}
    </BulkImportContext.Provider>
}

function useBulkImport() {
    return useContext(BulkImportContext)
}
export default function BulkImportPage() {
    return (
        <BulkImportProvider>
            <AnnuityProvider>
                <BulkImportPageTable />
            </AnnuityProvider>
        </BulkImportProvider>
    )
}

function BulkImportPageTable() {
    const {t} = useTranslation()

    const [showImportDialog, setShowImportDialog] = useState(false)

    const subStates = useFilterSubStates()
    // const [sortField, setSortField] = useState('internalReference')
    // const [sortOrder, setSortOrder] = useState(1)
    // const [selected, setSelected] = useState({})
    const {sortField, setSortField, sortOrder, setSortOrder, selected, setSelected} = useBulkImport()
    const { maintenanceActionsByDennemeyerId, ipRightByDennemeyerId, } = useAnnuity()
    //console.log({ maintenanceActionsByDennemeyerId, ipRightByDennemeyerId, })

    const {members} = useFilteredPatents()
    const {calculateDueDates} = useTimeline()
    const {ipRightByMemberId, validationsByIpRightId, instructionByDennemeyerId} = useDennemeyer()
    //console.log({ipRightByDennemeyerId, l: _.size(ipRightByDennemeyerId)})

    const sortFunction = makeSortFunction(sortField)
    const rows = _(members)
        .map(member => {
            const pcIpRight = ipRightByMemberId[member.familyMemberId]
            const ipRight = ipRightByDennemeyerId[pcIpRight?.dennemeyerId]
            const validationErrors = validationsByIpRightId[pcIpRight?.ipRightId] ?? []
            const renewalStatus = renewalStatusExtraction({ member, pcIpRight, ipRight, validationErrors })

            const maintenanceActions = maintenanceActionsByDennemeyerId[ipRight?.DennemeyerId] ?? []
            const maintenanceActionStatus = maintenanceActionsStatus({renewalStatus: renewalStatus.status, maintenanceActions, calculateDueDates, instructionByDennemeyerId})

            const row = {
                id: member.familyMemberId,
                member,
                ipRight,
                internalReference: member.internalReference,
                renewalStatus,
                validationErrors,
                maintenanceActionStatus,
            }
            //if (member.internalReference === 'COM-P015WOJP') {
            //console.log({row, ipRight, pcIpRight}) }
            return row
        })
        .filter(row => subStates.length === 0 || subStates.includes(row.renewalStatus.substatus))
        .sortBy(sortFunction)
        .thru(rows => sortOrder === 1 ? rows : rows.reverse())
        .value()

    const selectedCount = _(selected).filter(s => s).size()
    const anySelected = _.some(selected, s => s)
    //console.log({anySelected})
    const importButton = (
        <div className="w-full flex flex-row-reverse mt-2 max-w-3xl">
            <button onClick={() => setShowImportDialog(s => !s)} disabled={!anySelected} className="btn-primary py-px! text-sm">{t('import')}{selectedCount > 0 && ` (${selectedCount})`}</button>
        </div>
    )

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('bulk-import')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>
        <div className="header-row pb-1 sr-only">
            <h2 className="modern-h2">{t('bulk-import')}</h2>
        </div>
        <div className="main-content-pure px-2 sm:px-4 pb-6 pt-2">
            <div className="max-w-3xl">
                <ImportFilter />
            </div>
            {importButton}
            {showImportDialog && <ImportDialog {...{selected, rows, close: () => setShowImportDialog(false)}} />}
            <table className="mt-2 overflow-auto lg:min-w-3xl">
                <thead>
                    <tr className="border-b-2 border-pcx-300">
                        <th className="px-2 first:pl-1 last:pr-1 py-2">
                            <SelectAll {...{selected, setSelected, rows}}/>
                        </th>
                        {headers.map(header =>
                            <th key={header} className="px-2 first:pl-1 last:pr-1 py-2 whitespace-nowrap text-left">
                                <div className="text-left text-pcx-600 text-xs whitespace-nowrap font-semibold uppercase tracking-wider flex flex-row gap-1 items-center">
                                    {t(header)}
                                    <SortButton {...{sortField, setSortField, sortOrder, setSortOrder, searchField: header}} />
                                </div>
                            </th>)}
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row =>
                        <tr key={row.id} className="hover:bg-pcx-200 align-top">
                            <td>
                                {canBeSelected(row.renewalStatus) &&
                                    <label className="block w-full h-full pl-1">
                                        <input type="checkbox" className="form-checkbox" checked={selected[row.id]} onChange={() => setSelected({ ...selected, [row.id]: !selected[row.id] })} />
                                    </label>}
                            </td>
                            {headers.map(header =>
                                <td key={header} className="px-2 first:pl-1 last:pr-1 py-1 align-top ">{
                                    header === 'internalReference' ? <ReferenceCell {...{ row }} /> :
                                    header === 'renewalStatus' ? <RenewalStatusCell {...{ row }} /> :
                                    header === 'maintenanceActionStatus' ? <MaintenanceActionStatusCell {...{ row }} /> :
                                    header === 'validationErrors' ? <ValidationErrorsCell {...{ row }} /> :
                                    header === 'Dennemeyer ID' ? <DennemeyerIdCell {...{ row }} /> :
                                    null}
                                </td>)}
                        </tr>)}
                </tbody>
                <tfoot>
                    <tr className="border-b-2 border-pcx-300"></tr>
                </tfoot>
            </table>
            {importButton}
        </div>
    </>
}

const headers = [
    'internalReference',
    'renewalStatus',
    'maintenanceActionStatus',
    'validationErrors',
    'Dennemeyer ID',
]

function makeSortFunction(sortField: string) {
    switch (sortField) {
        case 'internalReference': return (row: Row) => row.internalReference.toLocaleLowerCase()
        case 'renewalStatus': return (row: Row) => {
            if (row.renewalStatus.substatus === 'ignored')
                return `${row.renewalStatus.status}/${row.renewalStatus.substatus}/${row.renewalStatus.reason}`.toLocaleLowerCase()
            else
                return `${row.renewalStatus.status}/${row.renewalStatus.substatus}`.toLocaleLowerCase()
        }
        case 'validationErrors': return (row: Row) => row.validationErrors
        case 'Dennemeyer ID': return (row: Row) => row.ipRight?.DennemeyerId
        case 'maintenanceActionStatus': return (row: Row) => row.ipRight?.NextMaintenanceActionDueDate
        default: return (row: Row) => row.id
    }
}

type Row = {
    id: number
    member: Member
    ipRight?: IpRight
    internalReference: string
    renewalStatus: RenewalStatusInfo
    validationErrors: ValidationMessage[]
    maintenanceActionStatus: MaintenanceActionStatusInfo
}

function SelectAll({selected, setSelected, rows}: {selected: Record<number, boolean>, setSelected: (selected: Record<number, boolean>) => void, rows: Row[]}) {
    const {t} = useTranslation()

    const canImport = rows.filter(r => canBeSelected(r.renewalStatus))
    const allSelected = canImport.length > 0 && canImport.every(row => selected[row.id])

    function onClick() {
        setSelected(_.fromPairs(canImport.map(row => [row.id, !allSelected])))
    }

    return (
        <label className="w-full h-full flex flex-flow gap-2 items-center pl-px">
            <input type="checkbox" className="form-checkbox" checked={allSelected} onChange={onClick} />
            <div className="label text-xs font-semibold uppercase tracking-wider">{t('all')}</div>
        </label>
    )
}
function DennemeyerIdCell({row}: {row: Row}) {
    return <div className="text-slate-500 text-xs font-mono">{row.ipRight?.DennemeyerId ?? ''}</div>
}

function ReferenceCell({row}: {row: Row}) {
    return <Link
        className="text-pcx-700 hover:text-pcx-600 font-medium whitespace-nowrap" 
        to={memberUrl(row.member)}
    >
        {row.internalReference}
    </Link>
}

const baseStyle = "size-4 mb-0.5 mr-1 inline"
function RenewalStatusCell({row}: {row: Row}) {
    const {t} = useTranslation()
    const {isLoadingAnnuity} = useAnnuity()
    if (isLoadingAnnuity) {
        return <div className="whitespace-nowrap">{t('loading')}...</div>
    }
    return <div className="whitespace-nowrap flex flex-row items-center gap-1">
        {iconByState[row.renewalStatus.substatus]}
        {renewalStatusToString(row.renewalStatus, t)}
    </div>
}

function ValidationErrorsCell({row}: {row: Row}) {
    const {t} = useTranslation()
    if (row.renewalStatus.substatus === 'missing-data') {
        return <div className="flex flex-wrap gap-x-1">{
            (row.renewalStatus.messages ?? []).map((msg, i) => 
                <span key={i} className="text-xs text-slate-700 after:content-[';'] last:after:content-['']">
                    {t(msg)}
                </span>)
        }</div>
    } else {
        return row.validationErrors.map((error, i) =>
            <div key={i} title={error.message} className="text-xs text-slate-700">{t(`dm_messages.${error.errorCode}`)}</div>)
    }
}

function MaintenanceActionStatusCell({row}: {row: Row}) {
    const {isLoadingAnnuity} = useAnnuity()
    const {t} = useTranslation()
    if (isLoadingAnnuity) {
        return <div className="whitespace-nowrap">{t('loading')}...</div>
    }
    return <div className="whitespace-nowrap">{maintenanceActionHistoryToString(row.maintenanceActionStatus, t)}</div>
}

const importFilterStates = [
    { label: 'ready', cls: "border-green-400 bg-green-100 text-green-900", substates: ['paid-for-life', 'back-office-processing', 'fully-ready'], icon: <CheckCircleIcon className="size-4 text-green-500" />},
    { label: 'can-import', cls: "border-pcx-400 bg-pcx-200 text-pcx-900", substates: ['can-import'], icon: <PlayCircleIcon className="size-4 text-pcx-400" />},
    { label: 'has-errors', cls: "border-amber-400 bg-amber-100 text-amber-900", substates: ['has-errors', 'missing-data'], icon: <ExclamationCircleIcon className="size-4 text-amber-500 "/>},
    { label: 'cannot-import', cls: "border-warn-400 bg-warn-100 text-warn-900", substates: ['ignored', 'stopped', 'inactive'], icon: <StopCircleIcon className="size-4 text-warn-400" />},
] as ImportFilterButtonProps[]

const importFilterStateByLabel = _.keyBy(importFilterStates, 'label')
const iconByState = _(importFilterStates).flatMap(s => s.substates.map(substate => [substate, s.icon])).fromPairs().value()

interface ImportFilterButtonProps {
    label: string
    substates: RenewalSubStatus[]
    icon: React.ReactNode
    cls: string
}

const filterTag = 'import-filter'

function useFilterSubStates() {
    const [searchParams] = useSearchParams()
    const filterParams = (searchParams.get(filterTag) ?? '').split(',').filter(f => f !== '')
    return filterParams.flatMap(f => importFilterStateByLabel[f].substates)
}

function ImportFilterButton({label, icon, cls}: ImportFilterButtonProps) {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()

    const filterParams = (searchParams.get(filterTag) ?? '').split(',').filter(f => f !== '')
    const isActive = filterParams.includes(label)
    function onClick() {
        setSearchParams(createSearchParams({
            [filterTag]: isActive
                ? filterParams.filter(f => f !== label).join(',')
                : [...filterParams, label].join(',')
        }))
    }

    return (
        <button key={label} onClick={onClick} className={clsx(
            cls,
            "rounded-md border px-2 py-1 text-xs whitespace-nowrap",
            "flex flex-row gap-2 items-center",
            !isActive && "opacity-50 hover:opacity-70"
        )} >
            {icon}
            {t(label)}
        </button>
    )
}

function ImportFilter() {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2">{
            importFilterStates.map(f => <ImportFilterButton key={f.label} {...f} />)
        }</div>
    )
}

function ImportDialog({selected, close, rows}: {selected: Record<number, boolean>, close: () => void, rows: Row[]}) {
    const {t} = useTranslation()

    const { owners, reload } = useDennemeyer()
    const fallbackOwnerId = owners[0].ownerId
    const {deriveInitial, isLoading, postIpRights} = useIpRightsImport(fallbackOwnerId)

    const toImport = rows.filter(r => selected[r.id])

    async function onSubmit({ownerId, costCenters}: {ownerId: number, costCenters: (PcCostCenter & PcCostCenterLink)[]}) {
        //console.log({'Importing': toImport, ownerId, costCenters})
        const ipRights = toImport
            .map(row => row.member.familyMemberId !== undefined ? deriveInitial(row.member as { familyMemberId: number }) : undefined)
            .filter(Boolean)
        await postIpRights(ipRights)
        reload()
        close()
    }

    if (isLoading) return null

    if (!fallbackOwnerId) {
        return <NoOwnerModal {...{close}} />
    }

    const initialValues = {
        ownerId: fallbackOwnerId,
        costCenters: [],
    }

    return (
        <Modal overflowHidden={false}>
            <Formik {...{onSubmit, initialValues}}>
                <Form>
                    <div className="p-4">
                        <h2 className="mb-4">Importing {toImport.length}</h2>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="costCenters" className="label">{t('cost-centers')}</label>
                            <CostCenterSelectionField name="costCenters" />

                            <div />

                            <label htmlFor="ownerId" className="label">{t('owner')}</label>
                            <Field id="ownerId" name="ownerId" as="select" className="form-select">
                                {owners.map(owner => <option key={owner.ownerId} value={owner.ownerId}>{owner.name}</option>)}
                            </Field>
                        </div>
                    </div>

                    <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                        <input type="submit" className="btn-primary" value={t('import')} />
                        <button className="btn-secondary" onClick={close}>{t('close')}</button>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

function CostCenterSelectionField({name}: {name: string}) {
    const [, meta, helpers] = useField<(PcCostCenter & PcCostCenterLink)[]>(name)
    const { setValue } = helpers
    const { value } = meta

    return <CostCenterSelection {...{
        value, ipRightId: -1, onChange: ({ target: { value } }) => {
            setValue(value)
        }
    }} />
}

function NoOwnerModal({close}) {
    const {t} = useTranslation()
    return (
        <Modal>
            <div className="p-4">
                <h3 className="mb-2">{t('no-owner-defined')}</h3>
                <p>
                    <Trans
                        i18nKey='define-owner'
                        components={{ here: <Link className="underline decoration-pcx-500 hover:decoration-pcx-400" to="/renewals/owners/add" /> }}
                    />
                </p>
            </div>
            <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                <Link className="btn-primary" to="/renewals/owners/add">{t('add-owner-details')}</Link>
                <button onClick={close} className="btn-secondary">{t('cancel')}</button>
            </div>
        </Modal>
    )
}