import { useState, useCallback, memo, useMemo } from "react"
import { Combobox, RadioGroup } from '@headlessui/react'
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useInventions } from "../inventions/InventionsProvider";
import { useTrademarks } from "../trademarks/TrademarksProvider";
import { Family } from "../patents/patents";
import Fuse from "fuse.js";
import _ from "lodash";
import { family_member, patent_family, trade_mark, trademark_family } from "../data";
import { useRoles } from "../user/Auth";
import { decorateMatches, extractLink } from "./utils";
import { useFilter } from "../filter/Filter";
import { free_text_filter, reference_filter } from "../filter/utils";
import { usePatents } from "../patents/PatentsProvider";
import { IconBox, IconCalendar, IconLightBulb, IconShieldCheck, IconTradeMark } from "../components/icons";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { ListBulletIcon } from "@heroicons/react/24/solid";
import { useProducts } from "../products/ProductsProvider";
import { useTasks } from "../tasks/TasksProvider";
import { augment } from "../tasks/utils";
import { useUserSettings } from "../user/UserSettingsProvider";
import { useTags } from "../tags/TagsProvider";


const SearchResults = memo(function SearchResults({ query, focus, search }: { query: string, focus: string, search: (searchTerm: string) => any }) {
    const {t} = useTranslation()

    const lc_query = query?.toLowerCase()
    const results = query.length > 0 ? search(lc_query) : { families: [], members: [], commodities: [], inventions: [], trademarks: [], trademarkFamilies: [], tasks: [] }

    const searchResults = (query === '' || results === undefined)
        ? []
        : _([
            ...results.families,
            ...results.members,
            ...results.commodities,
            ...results.inventions,
            ...results.trademarks,
            ...results.trademarkFamilies,
            ...results.tasks,
        ])
            .filter(r => focus === 'all' || r.type === focus)
            .map((result) => ({ ...result, ...(extractLink(result.type, result.item) ?? {}) }))
            .filter(r => r.to !== undefined && r.reference !== undefined)
            .sortBy('score')
            .value() as ResultProps[]

    //console.log({searchResults, results})

    return <>
        {query !== '' && <QueryActions {...{ query }} />}

        {searchResults.length > 0 && <>
            <Combobox.Options className="max-h-[60vh] scroll-py-2 overflow-y-auto p-2 text-sm" static >
                {searchResults.map((result, i) => <SearchResult key={i} {...{ result: { ...result } }} />)}
            </Combobox.Options>
        </>}

        {query !== '' && searchResults?.length === 0 && (
            <div className="px-6 py-14 text-center sm:px-14">
                {/* <FolderIcon className="mx-auto h-6 w-6 text-gray-900 text-opacity-40" aria-hidden="true" /> */}
                <p className="mt-4 text-sm text-gray-900">
                    {t('no-results-try-again')}
                </p>
            </div>
        )}
    </>
})

type ResultProps = Fuse.FuseResult<any> & {type: string, to: string, reference: string}

function comboboxOptionStyle(active: boolean) {
    return clsx(
        "text-sm text-gray-700 px-3 py-2 block rounded-md hover:bg-gray-900 hover:bg-opacity-5 hover:text-gray-900",
        "flex flex-row gap-4 items-center",
        active && "bg-gray-900 bg-opacity-5 text-gray-900"
    )
}

function SearchResult({ result }: { result: ResultProps }) {
    const {t} = useTranslation()
  // const {previewLink} = useBrowser()

    return (
        <Combobox.Option className='list-none cursor-pointer group' value={result}>{({ active }) =>
            <div className={comboboxOptionStyle(active)} >
                <div className="w-6">
                    {typeIcons[result.type]}
                </div>
                <div>
                    <div className={clsx("font-medium", active ? "text-pcx-900" : "text-pcx-700")}>
                        <span className="group-hover:underline">{result?.reference}</span> <span className={clsx(active ? "text-gray-900" : "text-gray-700", "font-base")}>({t(result.type)})</span>
                    </div>
                    <RenderMatch match={result?.matches?.[0]} active={active} />
                </div>
            </div>
        }</Combobox.Option>
    )
}

function RenderMatch({match, active}: {match?: Fuse.FuseResultMatch, active: boolean}) {
    if (!match) return null

    const rendered = decorateMatches(match)

    return <div className={clsx("pt-1", active ? "text-gray-900" : "text-gray-700")} dangerouslySetInnerHTML={{__html: rendered}} />
}

const typeIcons = {
    [patent_family]: <IconShieldCheck />,
    [family_member]: <IconShieldCheck />,
    [trade_mark]: <IconTradeMark />,
    [trademark_family]: <IconTradeMark />,
    'invention': <IconLightBulb />,
    'commodity': <IconBox />,
    'task': <IconCalendar />,
}

interface QueryAction {
    callback: () => void
}

function QueryActions({query}: {query: string}) {
    const {t} = useTranslation()
    const {addFilter} = useFilter()

    function addFreeTextFilter() {
        addFilter({name: free_text_filter, config: query, display: query, isActive: true})
    }
    function addReferenceFilter() {
        const config = query.split(',').map(r => r.trim()).join('\n')
        addFilter({name: reference_filter, config, display: query, isActive: true})
    }

    const actions = [
        {callback: addFreeTextFilter,  label: t('add-query-as-free-text-filter', {query}), icon: <FunnelIcon className="h-5 w-5 opacity-80" />},
        {callback: addReferenceFilter, label: t('add-query-as-reference-filter', {query}), icon: <ListBulletIcon className="h-5 w-5" />},
    ]

    return (
        <Combobox.Options className="max-h-[80vh] scroll-py-2 overflow-y-auto p-2 text-sm" static >
            {actions.map((action, i) => <Combobox.Option key={i} className='list-none' value={action}>{({ active }) =>
                <div className={comboboxOptionStyle(active)}>
                    <div className="w-6">
                        {action.icon}
                    </div>
                    <div className="font-medium text-pcx-700">
                        {action.label}
                    </div>
                </div>
            }</Combobox.Option>)}
        </Combobox.Options>
    )
}

const defaultFuseConfig = {
    includeScore: true,
    ignoreLocation: true,
    threshold: 0.3,
    includeMatches: true,
}

function useIpSearch() {
    const {hasBrands, hasInnovation, hasTasks} = useRoles()
    // What should we do with active /inactive IP rights?
    const { tagsLookup } = useTags()
    const { commodities } = useProducts()
    const { inventions } = useInventions()
    const { trademarks, trademarkFamilies, trademarkById } = useTrademarks()
    const { members, families: _families, familyById, memberById } = usePatents()
    const { todos } = useTasks()
    const {users} = useUserSettings()

    const families = _families.map((f: Family) => ({...f, tags: tagsLookup[patent_family]?.[f.patentFamilyId] ?? []}))

    const familyFuse = useMemo(() => new Fuse(families, { 
        ...defaultFuseConfig,
        keys: ['internalReference', 'familyName', 'summary', 'externalReference', 'tags'],
        
    }), [families])

    const memberFuse = useMemo(() => new Fuse(members, {
        ...defaultFuseConfig,
        keys: ['internalReference', 'externalReference', 'title', 'countryCode', 'applicationNumber', 'publicationNumber', 'patentNumber']
    }), [members])

    const productFuse = useMemo(() => new Fuse(commodities, {
        ...defaultFuseConfig,
        keys: ['commodityReference', 'commodityClass', 'commodityDescription'] 
    }), [commodities])

    const inventionFuse = useMemo(() => new Fuse(inventions, {
        ...defaultFuseConfig,
        keys: ['reference', 'title', 'summary'] 
    }), [inventions])

    const trademarkFuse = useMemo(() => new Fuse(trademarks, {
        ...defaultFuseConfig,
        keys: ['reference', 'words', 'applicationNumber', 'registrationNumber', 'countryCode'],
    }), [trademarks])

    const trademarkFamilyFuse = useMemo(() => new Fuse(trademarkFamilies, {
        ...defaultFuseConfig,
        keys: ['reference', 'name', 'description'],
    }), [trademarkFamilies])

    const taskFuse = useMemo(() => new Fuse(todos.map(t => ({...t, ...augment(t, familyById, memberById, trademarkById, users)})), {
        ...defaultFuseConfig,
        keys: ['title', 'comment', 'dueDate', 'internalDueDate' ],
    }), [todos, familyById, memberById, trademarkById, users])

    const search = useCallback(
        (searchTerm: string) => {

            const fuse_matched_families = familyFuse.search(searchTerm, { limit: 15 })
            const fuse_matched_members = memberFuse.search(searchTerm, { limit: 15 })
            const fuse_matched_products = productFuse.search(searchTerm, { limit: 15 })
            const fuse_matched_inventions = hasInnovation ? inventionFuse.search(searchTerm, { limit: 15 }) : []
            const fuse_matched_trademarks = hasBrands ? trademarkFuse.search(searchTerm, { limit: 15 }) : []
            const fuse_matched_trademark_families = hasBrands ? trademarkFamilyFuse.search(searchTerm, { limit: 15 }) : []
            const fuse_matched_tasks = hasTasks ? taskFuse.search(searchTerm, { limit: 15 }) : []
            //console.log({fuse_matched_tasks})

            return {
                families: fuse_matched_families.map(i => ({ ...i, type: patent_family })),
                members: fuse_matched_members.map(i => ({ ...i, type: family_member })),
                commodities: fuse_matched_products.map(i => ({ ...i, type: 'commodity' })),
                inventions: fuse_matched_inventions.map(i => ({ ...i, type: 'invention' })),
                trademarks: fuse_matched_trademarks.map(i => ({ ...i, type: trade_mark })),
                trademarkFamilies: fuse_matched_trademark_families.map(i => ({ ...i, type: trademark_family })),
                tasks: fuse_matched_tasks.map(i => ({ ...i, type: 'task' }))
            }
        }, [
        //families, members, commodities, inventions, trademarks, trademarkFamilies,
        hasInnovation, hasBrands, hasTasks,
        familyFuse, memberFuse, inventionFuse, productFuse, trademarkFamilyFuse, trademarkFuse, taskFuse,
    ])

    return {search}
}

export function useHandleIpResults() {
    const navigate = useNavigate()

    function handleIpResult(result: { to: string } | QueryAction) {
        if ('to' in result) {
            navigate(result.to);
        } else {
            result.callback()
        }
    }

    return {handleIpResult}
}


export function IpResults({query}: {query: string}) {
    const {t} = useTranslation()
    const {hasBrands, hasInnovation, hasTasks} = useRoles()
    const {search} = useIpSearch()

    const possibleFocus = [
        'all',
        patent_family,
        family_member,
        'commodity',
        hasInnovation && 'invention',
        hasBrands && trade_mark,
        hasBrands && trademark_family,
        hasTasks && 'task',
    ].filter(Boolean)

    const [focus, setFocus] = useState(possibleFocus[0])

    return <>
        {query !== '' &&
            <RadioGroup value={focus} onChange={setFocus} as='div' className='px-4 py-2'>
                <RadioGroup.Label className='font-medium text-slate-600 text-xs px-px'>{t('focus-on')}</RadioGroup.Label>
                <div className="flex flex-row gap-2 text-xs py-1.5">
                    {possibleFocus.map((f, i) =>
                        <RadioGroup.Option key={i} value={f}>{({ active, checked }) =>
                            <div className={clsx(
                                "px-2 py-1 rounded-sm hover:bg-gray-900 hover:bg-opacity-5 cursor-pointer",
                                active && "bg-gray-900 bg-opacity-5",
                                checked && "bg-pcx-400 bg-opacity-20"
                            )}>
                                {t(f)}
                            </div>
                        }</RadioGroup.Option>)}
                </div>
            </RadioGroup>}
        <SearchResults {...{ query, focus, search }} />
    </>
}