import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import _ from 'lodash'

import Modal from "../components/Modal";
import { DocumentInfo, MaintenanceAction, useDebitNote, useDocumentInfo, useDocumentsPerId, useIpRight, useRenewalNotice } from "./DennemeyerProvider";
import { usePatents } from "../patents/PatentsProvider";
import { FeeAmount, StatusView } from "./MaintenanceActionInstruction";
import { useAugmentMaintenanceAction } from "./utils";
import { useState } from "react";
import { IconSpinner } from "../components/icons";
import { useComments } from "../comments/CommentsProvider";
import { family_member } from "../data";
import { DueDateStatus } from "./settings/instruction_timeline";

export function RenewalsTrail() {
    const {t} = useTranslation()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const dennemeyerId = searchParams.get('dennemeyerId')
    const familyMemberId = +searchParams.get('familyMemberId')
    const {documents} = useDocumentsPerId(dennemeyerId)

    const annuityCertificates = documents?.Data?.Page?.filter(d => d.DocumentType === 'Annuity certificate') ?? []

    const {ipRight} = useIpRight(dennemeyerId)
    //const ipRight = _ipRight
    const {memberById} = usePatents()

    const member = memberById?.[familyMemberId]

    if (dennemeyerId === null || member === undefined) return null

    if (ipRight?.HasError) {
        console.warn(ipRight.ErrorDetails)
        return null
    }

    const maintenanceActions = ipRight?.Data?.MaintenanceActions ?? []

    const noData = annuityCertificates.length === 0 && maintenanceActions.length === 0

    return (
        <Modal escAction={() => navigate('..')} blurClick={() => navigate('..')}>
            <div className="p-4 bg-pcx-100">
                <Link className="text-slate-500 capitalize text-sm mb-4" to={'..'}><ChevronLeftIcon className="h-4 w-4 inline-block mb-0.5" /> {t('back')}</Link>
                <h3 className="mb-2">{t("instructions")}{member ? ': ' + member?.internalReference : ''}</h3>
                {noData
                    ? <p className="p-4 bg-white rounded-lg shadow text-center text-slate-800 text-lg">No data yet.</p>
                    : <Trail {...{familyMemberId, maintenanceActions, annuityCertificates}} />}
            </div>
            <div className="bg-pcx-100 flex flex-row-reverse p-4 pt-0">
                <Link className="btn-secondary capitalize" to={'..'}>{t('close')}</Link>
            </div>
        </Modal>
    )
}

function Trail({ familyMemberId, maintenanceActions, annuityCertificates }: { familyMemberId: number, maintenanceActions: MaintenanceAction[], annuityCertificates: DocumentInfo[] }) {
    const maintenanceActionsByYear = _(maintenanceActions)
        .groupBy(m => m.DueDate.slice(0, 4))
        .mapValues(ms => {
            if (ms.length === 1) return ms[0]
            else {
                const open = ms.filter(m => m.InstructionInfo === undefined)
                // let's open l -1 gives us the last one; can't say anything else about the ordering...
                if (open.length > 0) return open[open.length - 1]
                else
                return ms[ms.length - 1]
            }
        })
        .value()
    const certificatesByYear = _.keyBy(annuityCertificates, a => a.DocumentDate.slice(0, 4))
    const years = _(maintenanceActionsByYear).keys().concat(_.keys(certificatesByYear)).uniq().sort().reverse().value()
    //console.log({maintenanceActionsByYear, certificatesByYear, years})
    return (
        <div className="grid grid-cols-2 md:grid-cols-[auto_auto_auto_auto] gap-x-4 lg:gap-x-8 gap-y-2">
            {years.map( year => {
                const maintenanceAction = maintenanceActionsByYear[year]
                return maintenanceAction 
                    ? <MaintenanceActionHistory key={maintenanceAction.DennemeyerId} {...{ maintenanceAction, familyMemberId, certificate: certificatesByYear[year] }} />
                    : <CertificateView key={year} {...{certificate: certificatesByYear[year]}} />
            })}
        </div>
    )
}

function CertificateView({certificate}: {certificate?: DocumentInfo}) {
    if (!certificate) return null

    return (
        <div className="col-span-2 md:col-span-4 p-4 bg-white rounded-lg shadow">
            <DownloadButton documentId={certificate.DennemeyerId} />
        </div>
    )
}

export function RenewalsHistory() {
    const {t} = useTranslation()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const dennemeyerId = searchParams.get('dennemeyerId')
    const familyMemberId = searchParams.get('familyMemberId')

    const {ipRight} = useIpRight(dennemeyerId)
    //const ipRight = _ipRight
    const {memberById} = usePatents()

    const member = memberById?.[familyMemberId]

    if (dennemeyerId === null || member === undefined) return null

    if (ipRight?.HasError) {
        console.warn(ipRight.ErrorDetails)
        return null
    }

    const maintenanceActions = ipRight?.Data?.MaintenanceActions ?? []

    return (
        <Modal escAction={() => navigate('..')} blurClick={() => navigate('..')}>
            <div className="p-4 bg-pcx-100">
                <Link className="text-slate-500 capitalize text-sm mb-4" to={'..'}><ChevronLeftIcon className="h-4 w-4 inline-block mb-0.5" /> {t('back')}</Link>
                <h3 className="mb-2">{t("instructions")}{member ? ': ' + member?.internalReference : ''}</h3>
                <div className="grid grid-cols-2 md:grid-cols-[auto_auto_auto_auto] gap-x-4 lg:gap-x-8 gap-y-2">
                    {_(maintenanceActions)
                        .sortBy(m => m.DueDate)
                        .reverse()
                        //.map(maintenance => <MaintenanceActionHistoryView key={maintenance.DennemeyerId} {...maintenance} />)
                        // @ts-ignore
                        .map(maintenanceAction => <MaintenanceActionHistory key={maintenanceAction.DennemeyerId} {...{maintenanceAction, familyMemberId}} />)
                        .value()}
                </div>
            </div>
            <div className="bg-pcx-100 flex flex-row-reverse p-4 pt-0">
                <Link className="btn-secondary capitalize" to={'..'}>{t('close')}</Link>
            </div>
        </Modal>
    )
}

function MaintenanceActionHistory({maintenanceAction, certificate, familyMemberId}: {maintenanceAction: MaintenanceAction, certificate?: DocumentInfo, familyMemberId: number}) {
    const {t} = useTranslation()

    const {augmentMaintenanceAction} = useAugmentMaintenanceAction()
    const {status, instruction, instructionDueDate, fees} = augmentMaintenanceAction(maintenanceAction)
    const {commentsLookUp, commentById} = useComments()

    const instructionComment = commentById[instruction?.commentId]
    // either show specific comment for instruction or all comments from the same day
    const comments = instructionComment ? [instructionComment] : instruction?.created === undefined ? [] : (commentsLookUp[family_member]?.[familyMemberId] ?? [])
        .filter(c => {
            const date = c.created.substring(0, 10)
            const iDate = instruction.created.substring(0, 10)
            return date === iDate
        })
    //console.log({instructionComment, comments, instruction})
    
    return (
        <div className="col-span-2 md:col-span-4 grid grid-cols-subgrid gap-y-2 items-baseline p-4 bg-white rounded-lg shadow">
            <div className="max-md:col-span-2 grid grid-cols-[auto_auto] gap-x-2 items-baseline" title={maintenanceAction.DueDate}>
                <span className="md:text-lg font-semibold text-pcx-900 tabular-nums">{instructionDueDate}</span>
                <span className="text-slate-600 whitespace-nowrap text-sm">{t('instruction-due')}</span>
                <span className="text-slate-500 font-semibold md:text-lg tabular-nums">{maintenanceAction.DueDate}</span>
                <span className="text-slate-600 whitespace-nowrap text-sm">  {t('renewal-fee-due')}</span>
            </div>
            <div className="max-md:col-span-2">
                <StatusView {...{ maintenanceAction, status, instruction }} />
                {comments.map(({comment}, ci) => <div key={ci} className="text-slate-600 text-sm mt-2 max-w-prose">{comment}</div>)}
            </div>
            <div>
                {_(fees).toPairs().map(([currency, amount]) => <FeeAmount key={currency} {...{ currency, amount }} />).value()}
                {maintenanceAction.Annuity &&
                    <div className="whitespace-nowrap text-sm text-slate-600 mt-1.5">{t('annuity')}: {maintenanceAction.Annuity}</div>}
            </div>
            <div className="justify-self-end self-center"><ActionView {...{ status, maintenanceAction }} /></div>

            <div className="col-span-2 md:col-span-4 empty:hidden flex flex-col gap-1">
                {(maintenanceAction.FeesActual ?? []).map(({DNDennemeyerId}) =>
                    <DebitDocument key={DNDennemeyerId} DNDennemeyerId={DNDennemeyerId} />)}
                {(maintenanceAction.FeesEstimated ?? []).map(({RNDennemeyerId}) =>
                    <RenewalNotice key={RNDennemeyerId} RNDennemeyerId={RNDennemeyerId} />)}
                {certificate && <DownloadButton documentId={certificate.DennemeyerId} />}
            </div>
        </div>
    )
}

// from FeeActual
function DebitDocument({DNDennemeyerId}) {

    const {debitNote} = useDebitNote(DNDennemeyerId)
    const documentId = debitNote?.DocumentId

    return <DownloadButton {...{documentId}} />
}

// FeeEstiamted
function RenewalNotice({RNDennemeyerId}) {
    const {renewalNotice} = useRenewalNotice(RNDennemeyerId)
    const documentId = renewalNotice?.DocumentId

    return <DownloadButton {...{documentId}} />
}

/*
  Type (Date): Name
  Type: Name
  (Date): Name
  Name
*/
function DownloadButton({documentId}) {
    const {t} = useTranslation()
    const [isDownloading, setIsDownloading] = useState(false)

    const {documentInfo, downloadDocument} = useDocumentInfo(documentId)
    if (!documentInfo) return null

    const documentType = documentInfo.DocumentType ? t(documentInfo.DocumentType) : null
    const documentDate = documentInfo.DocumentDate?.substring(0, 10) ?? undefined

    const date = documentDate ? <span className="text-slate-600">({documentDate})</span> : null
    const metaData = documentType !== undefined || documentDate !== undefined
        ? <span className="text-slate-900">{documentType} {date}:</span> 
        : null

    return (
        <button 
            className="flex flex-row gap-2 items-center p-px text-pcx-500 hover:text-pcx-600 group text-sm"
            title={documentInfo.DocumentName}
            onClick={() => {
            setIsDownloading(true)
            downloadDocument(documentInfo.DocumentName).finally(() => setIsDownloading(false))
        }}>
            {metaData}
            <span className="group-hover:underline">{documentInfo?.DocumentName ?? t('download')}</span> 
            {isDownloading ? <IconSpinner className="h-4 w-4 animate-spin text-pcx-500" /> : <div className="h-4 w-4"/>}
        </button>
    )
}

export function ActionView({status, maintenanceAction}: {status: DueDateStatus, maintenanceAction?: MaintenanceAction}) {
    const {t} = useTranslation()
    if (maintenanceAction === undefined || status === 'too-late' || maintenanceAction.DennemeyerId === undefined) {
        return null
    } else {
        return (
            <Link className="btn-secondary text-sm w-fit h-fit" to={`/renewals/maintenances/instruct/${maintenanceAction.DennemeyerId}`}>
                {maintenanceAction?.PermanentOrder ? t('edit') : t('instruct')}
            </Link>
        )
    }
}
